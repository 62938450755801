import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const BusinessOffer1 = require('assets/images/landing/businessOffer1.png');
const BusinessOffer2 = require('assets/images/landing/businessOffer2.png');

export const BusinessOffer = () => {
  const { t } = useTranslation();

  return (
    <div className="py-10 px-6 lg:py-24">
      <div className="container mx-auto">
        <div className="md:w-2/3">
          <h2 className="landing-header-small mb-8 md:pr-20">{t('mainPage.headerBusinessOffers')}</h2>
          <div className="ml-20 lg:ml-40 mb-10 lg:mb-14">
            <p className="mb-6 font-medium">{t('mainPage.titleBusinessOffers')}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8">
          <div className="grid md:grid-cols-2 gap-6 lg:gap-16">
            <div>
              <Image alt={t('mainPage.headBusinessProfile')} placeholder="blur" src={BusinessOffer1} />
            </div>
            <div className="self-center">
              <h3 className="text-2xl md:text-3xl font-bold mb-6 w-3/4">{t('mainPage.headBusinessProfile')}</h3>
              <p className="font-light leading-6 w-11/12 mb-5">{t('mainPage.titleBusinessProfile1')}</p>
              <p className="font-light leading-6 w-11/12">{t('mainPage.titleBusinessProfile2')}</p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-6 lg:gap-16">
            <div className="self-center order-1 md:order-none">
              <h3 className="text-2xl md:text-3xl font-bold mb-6 w-3/4">{t('mainPage.headNameBusiness')}</h3>
              <p className="font-light leading-6 w-11/12 mb-5">{t('mainPage.titleNameBusiness1')}</p>
              <p className="font-light leading-6 w-11/12 mb-5">{t('mainPage.titleNameBusiness2')}</p>
              <p className="font-light leading-6 w-11/12">{t('mainPage.titleNameBusiness3')}</p>
            </div>
            <div>
              <Image alt={t('mainPage.headNameBusiness')} placeholder="blur" src={BusinessOffer2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
