import { FC, memo } from 'react';
import { useTranslation } from 'next-i18next';

import { CarsCatalog } from '@/features/CarsCatalog';
import { LocaleType } from '@/types';
import { useGetCars } from '@/utils/api';

type Props = {
  locale: LocaleType;
};

export const Cars: FC<Props> = memo(({ locale }) => {
  const { t } = useTranslation();
  const {
    isLoading: isLoadingCars,
    isError: isErrorCars,
    data: carsData,
    refetch: refetchCar,
  } = useGetCars({ locale });

  const cars = carsData ? carsData.data : [];

  return (
    <div className="px-6 sm:px-0 py-10 lg:py-24 container mx-auto">
      <h2 className="landing-header-small mb-4 lg:mb-8 pr-10 lg:pr-20">{t('mainPage.headerOurCars')}</h2>
      <p className="ml-20 lg:ml-40 mb-12 font-bold">{t('mainPage.titleOurCars')}</p>

      <CarsCatalog cars={cars} isError={isErrorCars} isLoading={isLoadingCars} locale={locale} refetch={refetchCar} />
    </div>
  );
});
