import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const MapCars = require('assets/images/landing/mapCars.png');
const AppStore = require('assets/icons/appleStore.svg');
const GooglePlay = require('assets/icons/googlePlay.svg');

import { Route } from '@/constants/routes';

export const InfoBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="background-block-color px-6 sm:px-0 py-10 lg:py-24">
      <div className="container mx-auto">
        <div className="w-full md:w-2/3">
          <h2 className="landing-header-small mb-4 md:mb-8 md:pr-20">{t('mainPage.headerRentCar')}</h2>
          <div className="md:ml-20 lg:ml-40">
            <p className="mb-6 font-medium">{t('mainPage.titleRentCar')}</p>
            <div className="flex gap-4 mb-20">
              <a className="transition hover:opacity-80 self-center justify-self-center" href={Route.Home}>
                <Image alt={t('appleStore')} src={AppStore} />
              </a>
              <a className="transition hover:opacity-80 self-center justify-self-center" href={Route.Home}>
                <Image alt={t('googlePlay')} src={GooglePlay} />
              </a>
            </div>
          </div>
        </div>

        <div className="ml-20 lg:ml-40 grid md:grid-cols-2 gap-6 lg:gap-16">
          <div>
            <Image alt="map" placeholder="blur" src={MapCars} />
          </div>
          <p className="font-light self-center text-sm leading-6 w-2/3">{t('mainPage.textRentCar')}</p>
        </div>
      </div>
    </div>
  );
};
