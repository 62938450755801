import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

const CustomersSlider = dynamic(() => import('@/features/CustomersSlider').then((module) => module.CustomersSlider), {
  ssr: false,
});

export const Customers = () => {
  const { t } = useTranslation();

  return (
    <div className="background-block-color py-10 px-6 lg:py-24">
      <div className="container mx-auto">
        <h2 className="landing-header-small mb-8 md:pr-20">{t('mainPage.headerWhatCustomersSay')}</h2>
        <div className="ml-20 lg:ml-40 mb-10 lg:mb-14 w-3/4 lg:w-1/2">
          <p className="font-medium">{t('mainPage.titleWhatCustomersSay')}</p>
        </div>
        <CustomersSlider />
      </div>
    </div>
  );
};
